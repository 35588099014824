/* eslint-disable camelcase */
/* eslint-disable id-length */
import { CamelcaseSerializer } from 'cerealizr'
import orderby from 'lodash.orderby'

import { fieldsToSnakeCase } from '@/utils/api'
import { formatImageUrl } from '@/utils/helpers'

import { api, csvApi, replicantApi } from '@/config/api'

const serializer = new CamelcaseSerializer()

const productSerializer = new CamelcaseSerializer({
  descriptor: {
    id: 'id',
    currency: 'currency',
    description: 'description',
    external_id: 'externalId',
    family: 'family',
    featured: 'featured',
    height: 'height',
    images: value =>
      value.map(image => {
        const newImage = serializer.serialize(image)
        newImage.imageUrl = formatImageUrl(newImage.imageUrl)
        return newImage
      }),
    length: 'length',
    metric_system: 'metricSystem',
    minimum_order_quantity: 'minimumOrderQuantity',
    name: 'name',
    printing_areas: value => value.map(obj => serializer.serialize(obj)),
    products: value => {
      const variants = serializer.serialize(value)
      const attributeQuantity = 3
      const newVariants = variants.map(variant => {
        const attributeSummaryList = [...Array(attributeQuantity).keys()].map(attrNum => {
          const attribute = variant[`attribute${attrNum + 1}`]
          const description = variant[`elementDescription${attrNum + 1}`]
          return attribute ? `${attribute}: ${description}` : ''
        })
        const mainVariant = variant.attribute2 ? `${variant.attribute2}: ${variant.elementDescription2}` : ''
        const productStockArrivalJoined = variant.productStockArrival ? `${variant.productStockArrival.stock}: ${new Date(variant.productStockArrival.date).toLocaleDateString('es-AR')}` : '0'
        const images = orderby(variant.images, 'createdAt')
        return { ...variant, images, attributeSummary: attributeSummaryList.join(', '), mainVariant, productStockArrivalJoined }
      })
      return newVariants
    },
    published: 'published',
    subfamily: 'subfamily',
    unit_weight: 'unitWeight',
    units_per_box: 'unitsPerBox'
  },
  mapAllValues: false
})

const serializeProductsResponse = response => {
  const { count, page, total_pages: totalPages, generic_products } = response.data
  return {
    data: {
      count,
      page,
      totalPages,
      genericProducts: generic_products.map(obj => productSerializer.serialize(obj))
    }
  }
}

const serializeProductResponse = response => {
  const { status, statusText, data } = response
  const { generic_product } = data
  return {
    data: {
      status,
      statusText,
      genericProduct: productSerializer.serialize(generic_product)
    }
  }
}

export const getProducts = ({ sort, ...params }) => {
  const baseUrl =
    sort && sort.by && sort.order ? `/generic_product?origin=BO&order[${sort.by}]=${sort.order}` : '/generic_product?origin=BO'
  return api.get(baseUrl, { params: { ...params, limit: 10 } }).then(serializeProductsResponse)
}

export const getProductsWepod = ({ sort, ...params }) => {
  const baseUrl =
    sort && sort.by && sort.order
      ? `/backoffice/generic_product?order[${sort.by}]=${sort.order}`
      : '/backoffice/generic_product'
  return api.get(baseUrl, { params: { ...params, limit: 10 } }).then(serializeProductsResponse)
}

export const getGenericProducts = params =>
  api.get('/generic_product', { params }).then(serializeProductsResponse)

export const getProduct = id =>
  api
    .get(`/backoffice/generic_product/${id}`)
    .then(serializeProductResponse)
    .catch(error => serializer.serialize(error.response))

export const editProduct = (id, product) => api.put(`/product/${id}`, product)

export const activeVariant = id => api.put(`/product/activate/${id}`)

export const activeAcromatic = id => api.put(`/product/achromatic/${id}`)

export const activeWepod = id => api.put(`/product/active_wepod/${id}`)

export const activeWepodGroup = (generic_product_id, color) =>
  api.put(`/product/active_wepod_group/${generic_product_id}/${color}`)

export const getPrintingAreas = idProduct =>
  api.get(`/generic_product/${idProduct}/printing_areas`).then(response => serializer.serialize(response))

export const addPrintingArea = (idProduct, area) =>
  api
    .post(`/generic_product/${idProduct}/printing_areas`, fieldsToSnakeCase(area))
    .then(response => serializer.serialize(response.data))

export const editPrintingArea = (idProduct, idArea, area, wepod) =>
  api
    .put(`/generic_product/${idProduct}/printing_areas/${idArea}/${wepod}`, fieldsToSnakeCase(area))
    .then(response => serializer.serialize(response.data))

export const editProductImage = (imageId, areaId, wepod) =>
  api
    .put(`/product_image/${imageId}`, { printing_area_id: areaId, wepod })
    .then(response => serializer.serialize(response.data))

export const deletePrintingArea = idArea =>
  api.delete(`/printing_areas/${idArea}`).then(response => serializer.serialize(response.data))

export const deletePrintingAreaWepod = idArea =>
  api.delete(`/printing_areas_wepod/${idArea}`).then(response => serializer.serialize(response.data))

export const getPrintingTypes = () =>
  api.get('/printing_type').then(response => serializer.serialize(response.data))

export const getPrintingTypesWepod = () =>
  api.get('/printing_type_wepod').then(response => serializer.serialize(response.data))

export const uploadCSVPrices = file => {
  const formData = new FormData()
  formData.append('csv', file)
  return csvApi.post('/generic_product/csv/update_price', formData)
}
export const uploadCSVArrivalStock = file => {
  const formData = new FormData()
  formData.append('csv', file)
  return csvApi.post('/generic_product/csv/arrival_stock', formData)
}

export const uploadSetupFile = file => {
  const formData = new FormData()
  formData.append('xls', file)
  return csvApi.post('/generic_product/xls/upload_setup', formData)
}

export const downloadSetupFile = filename => {
  const baseUrl = `/generic_product/xls/download_setup`
  return api({
    method: 'get',
    url: baseUrl,
    responseType: 'blob'
  }).then(response => {
    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fileLink = document.createElement('a')

    fileLink.href = fileURL
    fileLink.setAttribute('download', filename)
    document.body.appendChild(fileLink)

    fileLink.click()
  })
}

export const uploadCSVPrintingTypes = file => {
  const formData = new FormData()
  formData.append('csv', file)
  return csvApi.post('/printing_type/csv/update', formData)
}

export const uploadCSVLevels = file => {
  const formData = new FormData()
  formData.append('csv', file)
  return csvApi.post('/levels/csv', formData)
}

export const uploadCSVPointsUser = file => {
  const formData = new FormData()
  formData.append('csv', file)
  return csvApi.post('/users/add_points/csv', formData)
}

export const addSubattributes = (id, subattributes) =>
  api.post(`backoffice/generic_products/${id}/subattributes`, { subattribute_ids: subattributes })

export const updateProductDimensions = body => api.post('/generic_product_dimensions/update/', body)

export const setVariantTemplate = (id, templateId, data) =>
  api.put(`/product/set_template/${id}/${templateId}`, data)

export const updateProductPrintingTypes = body =>
  api.post('/backoffice/generic_products/printing_types', body)

export const setVariantPrintingColor = body => api.post('/product/set_printing_type_color', body)

export const variantPrintConfigAdd = (variantId, body) => api.put(`/product/variant_print_config_add/${variantId}`, body)
export const variantPrintConfigSave = body => api.post(`/product/variant_print_config_save`, body)
export const variantPrintConfigDel = (id, body) => api.delete(`/product/variant_print_config_del/${id}`, body)

export const setVariantPrintingColorGroup = body => api.post('/product/set_printing_type_color_group', body)

export const updateProductPrintingTypesWepod = body =>
  api.post('/backoffice/generic_products/printing_types_wepod', body)

export const setReplicantProduct = body =>
  replicantApi.post('/product', body)