export const permissions = {
  advanceDeliveryView: 'VIEW-ADVANCE-DELIVERY',
  advanceDeliveryEdit: 'EDIT-ADVANCE-DELIVERY',
  attributesAssign: 'ASSIGN-ATTRIBUTES',
  attributesEdit: 'EDIT-ATTRIBUTES',
  attributesView: 'VIEW-ATTRIBUTES',
  catalogsEdit: 'EDIT-CATALOGS',
  catalogsView: 'VIEW-CATALOGS',
  configBanners: 'BANNERS',
  configDeliveryPickup: 'EDIT-DELIVERY-PICKUP-VALUE',
  configDolar: 'EDIT-DOLLAR-VALUE',
  configView: 'CONFIGURATION-VIEW',
  holidaysView: 'VIEW-HOLIDAYS',
  holidaysEdit: 'EDIT-HOLIDAYS',
  orderCancelling: 'ORDER-CANCELLING',
  orderDownloadPdf: 'DOWNLOAD-PDF',
  orderDownloadPreviews: 'DOWNLOAD-PREVIEWS',
  orderDownloadVoucher: 'DOWNLOAD-VOUCHER',
  orderSetErp: 'SET-ERP',
  orderView: 'ORDER-VIEW',
  orderDeliveryView: 'ORDER-DELIVERY-VIEW',
  productAdvanceDelivery: 'ACTIVATE-ADVANCE-DELIVERY',
  productLogoFree: 'ACTIVATE-LOGO-FREE',
  partnerDashboard: 'ACTIVATE-DASHBOARD',
  partnerCashReservation: 'EDIT-CASH-RESERVATION',
  partnerEdit: 'PARTNER-EDIT',
  partnerEnable: 'PARTNER-ENABLE',
  partnerPoints: 'PARTNER-POINT',
  partnerUploadLoyalty: 'UPLOAD-LEVELS',
  partnerView: 'PARTNER-VIEW',
  partnerWhiteBoxEdit: 'WHITE-BOX-EDIT',
  partnertActivation: 'PARTNER-ACTIVATION',
  printingTypeEdit: 'EDIT-PRINTING-TYPES',
  printingTypeUpload: 'UPLOAD-PRINTING-TYPE-FILE',
  printingTypeView: 'VIEW-PRINTING-TYPES',
  productAchromaticActivation: 'ACTIVATE-ACHROMATIC',
  productCategories: 'EDIT-PRODUCT-CATEGORIES',
  productComercialImage: 'COMMERCIAL-IMAGE',
  productDescription: 'EDIT-DESCRIPTION',
  productDetailsView: 'VIEW-PRODUCT-DETAILS',
  productFeatured: 'FEATURED-PRODUCT',
  productMinQuantity: 'EDIT-MIN-QUANTITY',
  productPrice: 'EDIT-PRODUCT-PRICE',
  productPrintingAreas: 'EDIT-PRINTING-AREAS',
  productPrintingType: 'EDIT-PRODUCT-PRINTING-TYPE',
  productPrintingSettings: 'EDIT-PRODUCT-PRINTING-SETTINGS',
  productPublish: 'PUBLISH-PRODUCT',
  productTags: 'EDIT-TAGS',
  productUploadPrices: 'UPLOAD-PRICE-FILE',
  productUploadSetupFile: 'PRODUCT-UPLOAD-SETUP-FILE',
  productVariantActivation: 'ACTIVATE-VARIANT',
  productView: 'PRODUCT-VIEW',
  productWepodView: 'PRODUCT-WEPOD-VIEW',
  reservationAdd: 'EXTRAORDINARY-RESERVATION',
  reservationDelete: 'RESERVATION-DELETE',
  reservationView: 'RESERVATION-VIEW',
  rolesEdit: 'EDIT-ROLES',
  rolesView: 'VIEW-ROLES',
  usersEdit: 'EDIT-BO-USERS',
  usersView: 'VIEW-BO-USERS',
  advancesView: 'VIEW-ADVANCE-DELIVERY',
  advancesEdit: 'EDIT-ADVANCE-DELIVERY',
  complaintsView: 'VIEW-COMPLAINTS',
  complaintsEdit: 'EDIT-COMPLAINT',
  mercadopagoEdit: 'EDIT-MP-PAYMENT',
  expressDeliveryCountEdit: 'EDIT-EXPRESS-DELIVERY',
  viewSimpleSampleDistribution: 'VIEW-SIMPLE-SAMPLE-DISTRIBUTION',
  viewSimpleSamplePreparation: 'VIEW-SIMPLE-SAMPLE-PREPATION',
  productFamilies: 'VIEW-PRODUCT-FAMILIES',
  reportsView: 'VIEW-REPORT',
  downloadReport: 'DOWNLOAD-REPORT',
  editReport: 'EDIT-REPORT',
  createProductFamilies: 'CREATE-PRODUCT-FAMILIES',
  editProductFamilies: 'EDIT-PRODUCT-FAMILIES',
  manageDomains: 'VIEW-MANAGE-DOMAINS',
  kitsView: 'KITS-VIEW',
  viewDiscounts: 'VIEW-DISCOUNTS',
  printingProfiles: 'VIEW-PRINTING-PROFILES',
  editWepod: 'EDIT-WEPOD',
  logisticOperators: 'VIEW-LOGISTIC-OPERATORS',
  editLogisticOperators: 'EDIT-LOGISTIC-OPERATORS',
  productCariantPrintConfigEdit: 'PRODUCT-VARIANT-PRINT-CONFIG-EDIT'
}

const idAdminRole = 1

export const permissionsBlockedByRole = {
  [idAdminRole]: [permissions.rolesView, permissions.rolesEdit]
}

export const permissionsDependencies = {
  [permissions.usersView]: [permissions.rolesView]
}

export const checkPermission = (userPermissions, permission = '') =>
  userPermissions && userPermissions.length && userPermissions.includes(permission)
