import { isError } from '@/utils/api'

import { uploadImage } from '@/services/ImageService'
import { uploadImageAWS } from '@/services/s3Service'
import { getFamiliesFlat } from '@/services/ProductFamilyService'
import { isGreaterThan } from '@/utils/helpers'

import {
  deleteManager,
  editUser,
  editWhiteBox,
  editWhiteBoxV2,
  getManagers,
  getProvinces,
  getLevels,
  getAdvances,
  getUserDetails,
  getUsers,
  toggleEnableUser,
  toggleEnableWhiteBox,
  toggleEnableWBPrices,
  toggleAdvance,
  toggleMercadopago,
  toggleCheckPaymentEnabler,
  toggleLogoFreeEnabler,
  toggleDashboardEnabler,
  toggleWireTransfer,
  getWhiteBoxHostList
} from '@/services/UserService'

import { pageSizeDefault } from '@/constants/list'
import { usersStates } from '@/constants/users'
import { toggleWhiteBoxV2 as _toggleWhiteBoxV2, getPreOrders, sendActivationEmail } from '../../services/UserService'

export default {
  namespaced: true,

  state: {
    currentPage: 1,
    error: null,
    formErrors: [],
    loading: false,
    loadingSendMail: false,
    managerCurrentPage: 1,
    managerLoading: false,
    managerTotalRows: null,
    managers: [],
    partners: [],
    provinces: [],
    sort: {},
    success: null,
    totalRows: null,
    userDetails: null,
    hostList: [],
    levels: [],
    preOrders: null,
    families: [],
    loadingFamilies: false,
  },

  mutations: {
    SET_STATE_VALUE(state, { field, value }) {
      state[field] = value
    },
    SET_USERS(state, { users, count, sort }) {
      state.partners = users
      state.totalRows = count
      state.loading = false
      state.sort = sort
    },
    SET_USER_SUCCESS(state) {
      state.success = true
      state.error = null
      state.loading = false
    },
    SET_USER_ERROR(state, error = true) {
      state.success = null
      state.error = error
      state.loading = false
    },
    SET_USER_DETAILS_FORM_ERRORS(state, errors) {
      state.formErrors = errors
    },
    SET_WHITEBOX(state, whitebox) {
      state.userDetails.whiteBox = whitebox
    },
    SET_USER_PRE_ORDERS(state, preOrders) {
      state.userDetails.preOrders = preOrders
    },
    SET_WHITEBOX_VALUE(state, { field, value }) {
      state.userDetails.whiteBox[field] = value
    },
    SET_WHITEBOX_BANNER(state, signedUrl) {
      state.userDetails.whiteBox.banners.push({
        url: signedUrl.split('?')[0],
        order: state.userDetails.whiteBox.banners.length + 1
      })
    },
    REMOVE_WHITEBOX_BANNER(state, file) {
      const index = state.userDetails.whiteBox.banners.indexOf(file)
      if (index > -1) {
        state.userDetails.whiteBox.banners.splice(index, 1)
      }
    },
    SET_WHITEBOX_V2(state, whiteBoxV2) {
      state.userDetails.whiteBoxV2 = whiteBoxV2
    },
    SET_WHITEBOX_V2_VALUE(state, { field, value }) {
      state.userDetails.whiteBox[field] = value
    },
    SET_WHITEBOX_V2_BANNER(state, signedUrl) {
      state.userDetails.whiteBoxV2.banners.push({
        url: signedUrl.split('?')[0],
        order: state.userDetails.whiteBoxV2.banners.length + 1
      })
    },
    REMOVE_WHITEBOX_V2_BANNER(state, file) {
      const index = state.userDetails.whiteBoxV2.banners.indexOf(file)
      if (index > -1) {
        state.userDetails.whiteBoxV2.banners.splice(index, 1)
      }
    }
  },

  actions: {
    async hydrateUserDetails({ commit, dispatch, state }, id) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      const response = await getUserDetails(id).catch((error) => error.response)
      if (!isError(response.status)) {
        if (!state.hostList.length) dispatch('getWhiteBoxHostList')
        commit('SET_STATE_VALUE', { field: 'userDetails', value: response })
        commit('SET_STATE_VALUE', { field: 'loading', value: false })
      }
    },
    async getFamiliesFlat({ commit }) {
      commit('SET_STATE_VALUE', { field: 'loadingFamilies', value: true })
      const response = await getFamiliesFlat()
      if (isError(response.status)) {
        const { status, statusText } = response
        commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
        commit('SET_STATE_VALUE', { field: 'families', value: [] })
      } else {
        const { families } = response.data
        const familiesOrdened = [
          ...families.sort((op1, op2) => isGreaterThan(op1.description, op2.description))
        ]

        commit('SET_STATE_VALUE', {
          field: 'families',
          value: familiesOrdened
        })
      }
      commit('SET_STATE_VALUE', { field: 'loadingFamilies', value: false })
    },
    async getLevels({ commit }) {
      const response = await getLevels().catch((error) => error.response)
      if (!isError(response.status)) {
        commit('SET_STATE_VALUE', { field: 'levels', value: response.data })
      }
    },
    async getAdvances({ commit }, id) {
      const response = await getAdvances(id).catch((error) => error.response)
      if (!isError(response.status)) {
        commit('SET_STATE_VALUE', { field: 'advances', value: response.data })
      }
    },
    async hydrateProvinces({ commit }) {
      const response = await getProvinces({ isProvince: true }).catch((error) => error.response)
      if (!isError(response.status)) {
        commit('SET_STATE_VALUE', { field: 'provinces', value: response.data.provinces })
      }
    },
    getWhiteBoxHostList({ commit }) {
      getWhiteBoxHostList().then(
        (response) => commit('SET_STATE_VALUE', { field: 'hostList', value: response?.data?.hosts }),
        () => commit('SET_STATE_VALUE', { field: 'hostList', value: [] })
      )
    },
    async getPreOrders({ commit }, distributorUserId) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      await getPreOrders(distributorUserId).then(
        response => {
          commit('SET_USER_PRE_ORDERS', response.data)
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
        },
        ({ response }) => commit('SET_USER_ERROR', { data: response.data, status: response.status })
      )
    },

    editUserDetails({ commit }, { id, fields }) {
      return new Promise((resolve, reject) => {
        commit('SET_STATE_VALUE', { field: 'loading', value: true })
        editUser(id, fields).then(
          () => {
            commit('SET_USER_SUCCESS')
            resolve()
          },
          (e) => {
            const {
              response: {
                data: {
                  message: { errors: formErrors }
                }
              }
            } = e
            commit('SET_USER_DETAILS_FORM_ERRORS', formErrors)
            commit('SET_USER_ERROR')
            reject(formErrors)
          }
        )
      })
    },
    toggleEnableUserDetails({ commit }, { id, enabled, onFailure }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      toggleEnableUser(id, enabled).then(
        () => commit('SET_USER_SUCCESS'),
        () => {
          onFailure('enabled')
          commit('SET_USER_ERROR')
        }
      )
    },
    toggleAdvance({ commit }, { id, enabled, onFailure }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      toggleAdvance(id, enabled).then(
        () => commit('SET_USER_SUCCESS'),
        () => {
          onFailure('advance')
          commit('SET_USER_ERROR')
        }
      )
    },
    toggleMercadopago({ commit }, { id, enabled, onFailure }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      toggleMercadopago(id, enabled).then(
        () => commit('SET_USER_SUCCESS'),
        () => {
          onFailure('mercadopago')
          commit('SET_USER_ERROR')
        }
      )
    },
    toggleCheckPaymentEnabler({ commit }, { id, enabled, onFailure }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      toggleCheckPaymentEnabler(id, enabled).then(
        () => commit('SET_USER_SUCCESS'),
        () => {
          onFailure('check')
          commit('SET_USER_ERROR')
        }
      )
    },
    toggleLogoFreeEnabler({ commit }, { id, enabled, onFailure }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      toggleLogoFreeEnabler(id, enabled).then(
        () => commit('SET_USER_SUCCESS'),
        () => {
          onFailure('check')
          commit('SET_USER_ERROR')
        }
      )
    },
    toggleDashboardEnabler({ commit }, { id, enabled, onFailure }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      toggleDashboardEnabler(id, enabled).then(
        () => commit('SET_USER_SUCCESS'),
        () => {
          onFailure('check')
          commit('SET_USER_ERROR')
        }
      )
    },
    toggleWireTransfer({ commit }, { id, enabled, onFailure }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      toggleWireTransfer(id, enabled).then(
        () => commit('SET_USER_SUCCESS'),
        () => {
          onFailure('wireTransfer')
          commit('SET_USER_ERROR')
        }
      )
    },
    resetError({ commit }) {
      commit('SET_STATE_VALUE', { field: 'error', value: null })
    },
    resetFormErrors({ commit }) {
      commit('SET_USER_DETAILS_FORM_ERRORS', [])
    },
    resetSuccess({ commit }) {
      commit('SET_STATE_VALUE', { field: 'success', value: null })
    },
    async getUsers(
      { commit },
      // eslint-disable-next-line no-magic-numbers
      { page = 1, pageSize = pageSizeDefault, filters = {}, sort, limit = 10 } = {}
    ) {
      commit('SET_STATE_VALUE', { field: 'currentPage', value: page })
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      if (filters.states) filters.states = [filters.states]
      const params = { page, pageSize, ...filters, sort, limit }
      const response = await getUsers(params).catch((error) => error.response)
      if (!isError(response.status)) {
        const { users, count } = response.data
        const partners = users.map((user) => ({ ...user, userState: usersStates[user.state] }))
        commit('SET_USERS', { users: partners, count, sort })
      }
    },
    async saveWhiteBoxLogo({ commit }, file) {
      const fileNameSplit = file.name.split('.')
      const imageData = {
        file_name: file.name,
        extension: fileNameSplit.pop(),
        path: '/whitebox_logo'
      }
      const { data } = await uploadImage(imageData)
      const signedUrl = data.signed_url
      await uploadImageAWS(signedUrl, file)
      commit('SET_WHITEBOX_VALUE', { field: 'logoUrl', value: signedUrl.split('?')[0] })
    },
    toggleEnableUserWhiteBox({ commit }, { id, onFailure }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      commit('SET_WHITEBOX_VALUE', { field: 'enableError', value: false })
      toggleEnableWhiteBox(id).then(
        (response) => {
          commit('SET_USER_SUCCESS')
          commit('SET_WHITEBOX', response.whiteBox)
          commit('SET_WHITEBOX_V2', response.whiteBoxV2)
        },
        () => {
          onFailure('active')
          commit('SET_WHITEBOX_VALUE', { field: 'enableError', value: true })
          commit('SET_USER_ERROR')
        }
      )
    },
    toggleEnableWBPrices({ commit }, { id, onFailure }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      commit('SET_WHITEBOX_VALUE', { field: 'enableError', value: false })
      toggleEnableWBPrices(id).then(
        (response) => {
          commit('SET_USER_SUCCESS')
          commit('SET_WHITEBOX', response)
        },
        () => {
          onFailure('priceEnable')
          commit('SET_USER_ERROR')
        }
      )
    },
    editUserWhiteBox({ commit }, { id, whitebox, all = true }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      editWhiteBox(id, { ...whitebox, ...{ all } }).then(
        (response) => {
          commit('SET_USER_SUCCESS')
          commit('SET_WHITEBOX', response)
        },
        ({ response }) => commit('SET_USER_ERROR', { data: response.data, status: response.status })
      )
    },
    async addBanner({ commit }, upload) {
      const { file } = upload
      const fileNameSplit = file.name.split('.')
      const imageData = {
        file_name: file.name,
        extension: fileNameSplit.pop(),
        path: '/partner_banners'
      }
      const { data } = await uploadImage(imageData)
      const signedUrl = data.signed_url
      await uploadImageAWS(signedUrl, file)
      commit('SET_WHITEBOX_BANNER', signedUrl)
    },
    removeBanner({ commit }, file) {
      commit('REMOVE_WHITEBOX_BANNER', file)
    },
    resetWhiteBox({ commit }) {
      commit('SET_WHITEBOX', null)
    },
    deleteManager(_, id) {
      // TODO: Integrate with api endpoint
      deleteManager(id)
    },
    getManagers(_, filters) {
      // TODO: Integrate with api endpoint
      getManagers(filters)
    },
    //  Whitebox versión 2.0
    resetWhiteBoxV2({ commit }) {
      commit('SET_WHITEBOX_V2', null)
    },
    toggleWhiteBoxV2({ commit }, { id, onFailure }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      // commit('SET_WHITEBOX_V2_VALUE', { field: 'enableError', value: false })
      _toggleWhiteBoxV2(id).then(
        (response) => {
          commit('SET_USER_SUCCESS')
          commit('SET_WHITEBOX_V2', response.whiteBoxV2)
        },
        () => {
          onFailure('active')
          // commit('SET_WHITEBOX_V2_VALUE', { field: 'enableError', value: true })
          commit('SET_USER_ERROR')
        }
      )
    },
    async editWhiteBoxV2({ commit }, { id, whitebox, all = true }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      
      try {
        const response = await editWhiteBoxV2(id, { ...whitebox, all })
        commit('SET_USER_SUCCESS')
        commit('SET_WHITEBOX_V2', response)
      } catch ({ response }) {
        commit('SET_USER_ERROR', { data: response.data, status: response.status })
      }
    },
    sendActivationEmail({ commit }, {distributorUser,  onSuccess, onFailure}) {
      commit('SET_STATE_VALUE', { field: 'loadingSendMail', value: true })
      sendActivationEmail(distributorUser)
      .then(
        () => {
          onSuccess()
        },
        () => onFailure()
      )
      .finally(() => commit('SET_STATE_VALUE', { field: 'loadingSendMail', value: false }))
    },

  }
}
